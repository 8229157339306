// import React, { useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import LayoutCom from './Router/LayoutCom';
import './App.css';
import './font.scss'
function App() {

  
//   useEffect(() => {
//     if (isMobile) {
//         (window.adsbygoogle || []).push({
//             google_ad_client: "ca-pub-8878716159434368",
//             enable_page_level_ads: true,
//         });
//     } else {
//         (window.adsbygoogle || []).push({
//             google_ad_client: "ca-pub-8878716159434368",
//             enable_page_level_ads: true,
//             overlays: { bottom: true }
//         });
//     }

//     // eslint-disable-next-line
// }, [])

  return (
    <>
      <LayoutCom></LayoutCom>
    </>
  );
}

export default App;
