import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/calendar/locale/en_GB';//英语
// import enUS from 'antd/es/calendar/locale/en_US';//美语
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // 国际化配置
  <ConfigProvider locale={enGB}>

    <App />

  </ConfigProvider>
);

reportWebVitals();
