// import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
// import { isMobile } from 'react-device-detect';
import routes from "./routes";

const LayoutCom = () => {


    return (
        <Router>
            <Routes>
                {routes.map((item, index) => {
                    return (
                        <Route
                            key={index}
                            path={item.path}
                            element={<item.component />}
                        />
                    );
                })}
            </Routes>
        </Router>
    );
};

export default LayoutCom;