// 前端路由
import { lazy } from "react";

// 引入组件
const home = lazy(() => import("../pages/Index"))
const detail = lazy(() => import("../pages/Detail"))
const tag = lazy(()=>import("../pages/Tag"))
const search = lazy(()=>import("../pages/SearchPage"))
const showText = lazy(()=>import("../pages/ShowText"))
const contactUs = lazy(()=>import("../pages/ContactUs"))
const allTags = lazy(()=>import("../pages/AllTags"))
const loading = lazy(()=>import('../pages/Loading'))
const errorPage = lazy(()=>import('../pages/ErrorPage'))
// const login = lazy(() => import("../pages/Login"))
// const ranking = lazy(() => import("../pages/Ranking"))

// 前端的路由：
// tag页面： /t/{nameid}
// 搜索页面： /s/{nameid}
// 玩游戏的页面： /game/{nameid}
// privacy policy等页面：/page/{nameid}
// contact us页面：/contact

//  现在没有，以后可能会有的：
// 所有tags的页面： /tags

const routes = [


    // tag页面：
    {
        path: "/t/:nameid",
        component: tag
    },

    // 搜索页面：
    {
        path: "/s/:nameid",
        component: search
    },

    // 玩游戏的页面:
    {
        path: "/game/:nameid",
        component: detail
    },

    // privacy policy等页面：
    // {
    //     path: "/page/:nameid",
    //     component: showText
    // },

    {
        path: "/page/About-Us",
        component: showText
    },
    {
        path: "/page/Privacy-Policy",
        component: showText
    },
    {
        path: "/page/Terms-Of-Use",
        component: showText
    },
    {
        path: "/page/To-Parents",
        component: showText
    },



    // contact us页面：
    {
        path: "/contact",
        component: contactUs
    },

    // // 所有tags的页面:
    {
        path: "/tags",
        component: allTags
    },

    // {
    //     path :"/login",
    //     component:login
    // },

    // {
    //     path :"/ranking",
    //     component:ranking
    // },

    {
        path:"/loading",
        component:loading,
    },

    {
        path:'/error',
        component:errorPage,
    },

    // 未匹配默认跳转路径
    {
        path: "/",
        component: home,
    },

    {
        path: "*",
        component: errorPage,
    }
]

export default routes